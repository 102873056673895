<ng-container
  *ngIf="design"
  [ngTemplateOutlet]="DESIGN_2"
></ng-container>
<ng-template #DESIGN_2>
  <app-page-header
    [title]="
      (donation?.donor?.name || '') +
      ': Confirm ' +
      (isAccepted ? 'payment.' : 'the donation.')
    "
    [charity]="donation?.charity"
    [hasBack]="isAccepted"
    (onBack)="back()"
  ></app-page-header>
  <div
    class="page-content"
    [ngClass]="{ 'has-back': isAccepted }"
    fxLayout="column"
    *ngIf="donation"
    fxLayoutGap="0px"
    fxLayoutGap.lt-md="10px"
    fxLayoutAlign="start start"
  >
    <div
      fxLayout="column"
      fxLayout.gt-sm="row"
      fxFlex.gt-sm="100"
      class="content-wrapper"
      fxFlex="100"
    >
      <form
        fxLayout="column"
        class="confirm-wrapper"
        [formGroup]="form"
        fxFlex.gt-lg="100"
      >
        <div class="zoom" fxLayout="column" fxLayoutAlign="start center">
          <div class="card" fxFlex fxLayout="column" fxLayout.gt-sm="row">
            <div fxLayout="column" fxFlex fxFlex.gt-sm="{{ isAccepted ? 100 : 50 }}">
              <p fxLayoutAlign="start start">
                Please follow the instruction to complete the payment.
              </p>
              <rspl-donation-estimate
                fxFlex
                #estimate
                *ngIf="
                  donation && donation.pricing && donation.adjustedSpecification
                "
                [pricing]="donation.pricing"
                [gratuity]="isAccepted ? gratuity * 100 : 0"
                [specification]="donation.adjustedSpecification"
                [showZeros]="false"
                [showUnitPrices]="false"
                [showDiscount]="donation.discount?.valid"
                [leadOrDonation]="donation"
                [discountedBy]="donation.charity?.logo"
                totalLabel="Total Cost"
                showBookingFee="none"
              ></rspl-donation-estimate>
            </div>
            <div fxLayout="column" fxFlex.gt-sm="50" *ngIf="!isAccepted">
              <div fxLayout="column" fxLayoutAlign="start start" class="tip">
                <h2>Tip</h2>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start start"
                  fxFlex
                  class="tips"
                >
                  <button
                    class="primary-btn"
                    (click)="toggleTip(tipTypes.TEN)"
                    [ngClass]="{ 'filled-btn': tip === tipTypes.TEN }"
                  >
                    10%
                    <span>{{ donationPrice * 0.1 | currency }}</span>
                  </button>
                  <button
                    class="primary-btn"
                    (click)="toggleTip(tipTypes.FIFTEEN)"
                    [ngClass]="{ 'filled-btn': tip === tipTypes.FIFTEEN }"
                  >
                    15%
                    <span>{{ donationPrice * 0.15 | currency }}</span>
                  </button>
                  <button
                    class="primary-btn"
                    (click)="toggleTip(tipTypes.TWENTY)"
                    [ngClass]="{ 'filled-btn': tip === tipTypes.TWENTY }"
                  >
                    20%
                    <span>{{ donationPrice * 0.2 | currency }}</span>
                  </button>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start">
                <div
                  fxFlex
                  class="custom-tip"
                  (click)="toggleCustom($event, inputElement)"
                  [ngClass]="{ selected: tip === tipTypes.CUSTOM }"
                >
                  <div class="form-row" fxLayout="column">
                    <label>Custom tip amount:</label>
                    <input
                      #inputElement
                      name="custom-tip"
                      type="text"
                      formControlName="tip"
                      matInput
                      currencyMask
                    />
                  </div>
                </div>
              </div>
              <p class="total">
                Total: <span>{{ totalPrice | currency }}</span>
              </p>
            </div>
          </div>
        </div>
        <div
          fxLayoutAlign="center center"
          class="signature"
          *ngIf="!isAccepted"
          style="padding-top: 40px;"
        >
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Signature</mat-label>
            <rspl-signature
              [options]="signaturePadOptions"
              (onEndEvent)="setSignature()"
            ></rspl-signature>
            <input matInput formControlName="signature" class="hidden" />
          </mat-form-field>
          <div class="signature-buttons">
            <mat-icon
              (click)="clearSignature()"
              *ngIf="signatureFormControl.value"
              >clear</mat-icon
            >
          </div>
        </div>
      </form>
      <div
        fxLayout="column"
        fxFlex="100"
        fxLayoutAlign="center center"
        fxLayoutGap="15px"
        class="pay-wrapper zoom"
        *ngIf="isAccepted"
      >
        <ng-container [ngTemplateOutlet]="STRIPE"></ng-container>
      </div>
    </div>
    <div class="error-wrapper zoom" fxLayoutAlign="center center">
      <p class="error" *ngIf="error">{{ error }}</p>
    </div>
    <div
      *ngIf="!isAccepted"
      fxLayout="column"
      class="page-buttons zoom"
      fxLayoutAlign="center center"
      fxFlex="100"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <button
          class="submit-button primary-page-btn"
          [disabled]="!donation"
          (oneClick)="goToPayment()"
          rsplDisableDoubleClick
          [ladda]="isSubmitting"
        >
          <mat-icon svgIcon="lock"></mat-icon>
          Continue to payment
        </button>
      </div>
      <p class="terms">
        By proceeding you agree with ReSupply's
        <a
          href="https://resupplyme.com/utility/donor-priority-pickups-terms-of-use"
          target="_blank"
          >Terms of Use and Privacy Policy</a
        >
      </p>
    </div>
    <div
      fxLayout="column"
      fxLayout.gt-sm="row"
      class="page-buttons zoom"
      fxLayoutAlign.gt-sm="end center"
      *ngIf="isAccepted"
    >
      <div fxLayout="column" fxLayoutAlign="end center">
        <button
          class="submit-button primary-page-btn"
          [disabled]="!donation || (!!newCard && !isLoaded)"
          (oneClick)="checkout()"
          rsplDisableDoubleClick
          [ladda]="isSubmitting"
        >
          <mat-icon svgIcon="lock"></mat-icon>
          Confirm payment
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #STRIPE>
  <div class="stripe-wrapper">
    <rspl-stripe
      *ngIf="paymentIntent && paymentIntent.client_secret"
      [paymentIntent]="paymentIntent"
      (onReady)="cardReady()"
      type="payment"
      [total]="{ amount: totalPrice * 100, label: 'Total Cost' }"
      (onSuccess)="onPaymentSuccess()"
      (onError)="onPaymentError($event)"
      [newCard]="newCard"
      (onPaymentSetupSuccess)="onPaymentSetupSuccess($event)"
    ></rspl-stripe>
  </div>
</ng-template>
